
const ConfigData_dev={
    simulateAPI:false,
    basename:"",
    SystemPathURL:'http://localhost:3000',
    BackendPathURL:'http://localhost/HG/BEN/soft/BEN_BE_GITHUB/ben-app-hotel/ben-app-hotel/public',
}

const ConfigData_predeploy={
    simulateAPI:false,
    basename:"/HG/BEN/soft/AppFrontEndBEN_GIT/jsa-software-git.ben-app-frontend/build",
    SystemPathURL:'http://127.0.0.1',
    BackendPathURL:'http://127.0.0.1/HG/BEN/soft/BEN_BE_GITHUB/ben-app-hotel/ben-app-hotel/public',
}

const ConfigData_prod={
    simulateAPI:false,
    basename:"",
    SystemPathURL:'https://app.buziosespiritualidade.com',
    BackendPathURL:'https://back.buziosespiritualidade.com',
}

var ConfigData=null
if(process.env.REACT_APP_STAGE === 'dev'){
    ConfigData = ConfigData_dev
}
if(process.env.REACT_APP_STAGE === 'predeploy'){
    ConfigData = ConfigData_predeploy
}
if(process.env.REACT_APP_STAGE === 'prod'){
    ConfigData = ConfigData_prod
}
if(ConfigData==null){
    //DEFAULT
    ConfigData = ConfigData_dev
}


export default ConfigData